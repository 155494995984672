import React from "react";
import styled from 'styled-components';

import Layout from "../components/Layout";
import { TitleContainer, Title, Container } from "../components/atoms";

const StyledMain = styled.main`
    @media (min-width: 640px){
        margin: 3rem 0;
    }
`

const PageNotFound = () => {
    return (
        <Layout>
            <StyledMain>
                <header>
                    <TitleContainer maxWidth="73.125em" gutter>
                        <Title as="h1" noMargin>Page not found</Title>
                    </TitleContainer>
                </header>
                <Container maxWidth="73.125em" as="article" gutter>
                    <p>It looks like the page you're looking for cannot be found. Please make sure the path in the address bar is correct.</p>
                </Container>
            </StyledMain>
        </Layout>
    )
}

export default PageNotFound